<template>
  <b-container fluid>
   <b-overlay :show="loader">
    <b-row>
      <b-col v-if="formData.org_id">
          <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="formData.org_id">
              {{ $t('elearning_config.schedule_setup') + ' ' + $t('globalTrans.details') }}
          </list-report-head>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <tbody>
                    <tr>
                      <th style="width: 22%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                      <th class="text-center" style="width: 3%">:</th>
                      <td style="width: 23%" class="text-left">{{ formData.circular_memo_no }}</td>
                      <th style="width: 22%" class="text-left">{{ $t('elearning_iabm.batch_no') }}</th>
                      <th style="width: 3%" class="text-center">:</th>
                      <td style="width: 25%" class="text-left">{{ $i18n.locale === 'bn' ? formData.batch_name_bn : formData.batch_name }}</td>
                    </tr>
                    <tr>
                      <th style="width: 22%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
                      <th class="text-center" style="width: 3%">:</th>
                      <td style="width: 25%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                      <th style="width: 22%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                      <th style="width: 3%" class="text-center">:</th>
                      <td style="width: 25%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                    </tr>
                    <tr>
                      <th style="width: 22%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                      <th style="width: 3%" class="text-center">:</th>
                      <td style="width: 25%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                      <th style="width: 22%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                      <th style="width: 3%" class="text-center">:</th>
                      <td style="width: 25%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
                <b-overlay :show="load">
                  <b-row>
                    <div class="col-md-12"><span style="font-weight: bold;">{{ $t('elearning_tpm.training_duration') }} {{'('}}{{ circularData.training_start_date | dateFormat }} {{'-'}} {{ circularData.training_end_date | dateFormat }}{{ ')' }}</span></div>
                  </b-row>
                  <b-row>
                    <div class="col-md-12" id="form">
                      <fieldset class="pt-2 w-100">
                        <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.training_schedule')}}</legend>
                        <slot v-if="routines.length>0">
                          <div v-for="(routine,index) in routines" :key="index">
                            <b-row class="pl-2 pr-2">
                              <b-col lg="6" sm="6" class="text-left pl-10">
                                <b>{{ $t('globalTrans.day') }}-{{$n(index+1)}}</b>
                              </b-col>
                              <b-col lg="6" sm="6"  class="text-right pr-10">
                                 <b>{{routine.date | dateFormat}} ({{dayGetName(routine.date)}})</b>
                              </b-col>
                              <b-col lg="12" sm="12">
                                <table class="table tables-data" style="width:100%" border="1">
                                  <thead class="thead">
                                    <tr>
                                      <b-th style="width:10%" class="text-center">{{ $t('globalTrans.time') }}</b-th>
                                      <b-th style="width:10%" class="text-center">{{$t('elearning_config.topics_session_e')}}</b-th>
                                      <b-th style="width:10%" class="text-center">{{$t('elearning_config.speaker_facilitator_e')}}</b-th>
                                      <b-th style="width:10%" class="text-center">{{$t('elearning_config.vanue_name')}}</b-th>
                                    </tr>
                                  </thead>
                                  <tr v-for="(schedule,index1) in routine.list" :key="index1">
                                    <td class="text-center">{{schedule.start_time | time12FormateTraining}} - {{schedule.end_time | time12FormateTraining}}</td>
                                    <td>
                                      {{ $i18n.locale === 'bn' ? schedule.topics_session_bn : schedule.topics_session }}
                                    </td>
                                    <td class="text-center">
                                      {{ $i18n.locale === 'bn' ? schedule.speaker_bn : schedule.speaker }}<slot v-if="schedule.trainer_id">{{ ',' }}</slot>
                                      <template v-if="schedule.trainer_id">
                                        <designation :item="schedule.personal_data.professional_info" type="admin"/>
                                        <!-- <workplace :item="schedule.personal_data.professional_info" type="admin"/> -->
                                        {{ getWorkPlace(schedule.personal_data.professional_info) }}
                                      </template>
                                    </td>
                                    <td class="text-center">
                                      {{ $i18n.locale === 'bn' ? schedule.venue_bn : schedule.venue }}
                                    </td>
                                  </tr>
                                </table>
                              </b-col>
                            </b-row>
                          </div>
                        </slot>
                      </fieldset>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-md-12" id="form">
                      <slot v-if="committee">
                        <fieldset class="pt-2 w-100">
                          <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.committee_team')}}</legend>
                              <b-row class="pl-2 pr-2">
                                <b-col lg="12" sm="12">
                                  <table class="table tables-data" style="width:100%" border="1">
                                    <thead class="thead">
                                      <tr>
                                        <b-th style="width:10%" class="text-center">{{ $t('globalTrans.name') }}</b-th>
                                        <b-th style="width:10%" class="text-center">{{$t('globalTrans.designation')}}</b-th>
                                        <b-th style="width:10%" class="text-center">{{$t('elearning_config.committee_position')}}</b-th>
                                      </tr>
                                    </thead>
                                    <tr v-for="(committee,index1) in committee.details" :key="index1">
                                      <td class="text-center">{{ getCommitteeMember(committee.member_id) }}</td>
                                      <td class="text-center">
                                        {{ getDesignation(committee.designation_id) }}
                                      </td>
                                      <td class="text-center">
                                        {{ ($i18n.locale=='bn') ? committee.committee_position_bn : committee.committee_position_en }}
                                      </td>
                                    </tr>
                                  </table>
                                </b-col>
                              </b-row>
                        </fieldset>
                      </slot>
                    </div>
                  </b-row>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-overlay>
  </b-container>
</template>
<style>
.tables-data th, .tables-data td {
  padding: 0px 0px 0px 10px !important;
}
.tables-data {
  margin-bottom: 0px !important
}
</style>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { trainingScheduleRoutine, trainingScheduleCommittee, wordExportSchedule, designationWiseUser } from '../../api/routes'
import designation from './../../../component/designation'
// import workplace from './../../../component/workplace'
import moment from 'moment'

export default {
  components: {
    ListReportHead,
    designation
  },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getOfficerList()
      this.getCircularWiseData(tmp)
      this.committeeData(tmp)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      routines: [],
      circularData: {},
      committee: [],
      load: false,
      loader: false,
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      allOfficerList: []
    }
  },
  computed: {
  },
  methods: {
    async getOfficerList () {
        this.officerLoading = true
          const params = {
            org_id: parseInt(this.formData.org_id),
            office_type_id: parseInt(0)
          }
          await RestApi.getData(trainingElearningServiceBaseUrl, designationWiseUser, params)
          .then(response => {
            if (response.success) {
              this.allOfficerList = response.data
            }
            this.officerLoading = false
        })
      },
      getDesignation (id) {
        const office = this.$store.state.commonObj.designationList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
      },
      getCommitteeMember (id) {
        const officer = this.allOfficerList.find(item => parseInt(item.user_id) === parseInt(id))
        if (this.$i18n.locale === 'bn') {
            return officer !== undefined ? officer.name_bn : ''
        } else {
            return officer !== undefined ? officer.name : ''
        }
      },
    getWorkPlace (item) {
      if (parseInt(item.profession_type) === 1 && item.not_here_office === 0) {
        return ',' + this.getOfficeName(item.office_id)
      } else if (parseInt(item.profession_type) === 1 && item.not_here_office === 1) {
        return ',' + ((this.$i18n.locale === 'bn' ? item.office_name_bn : item.office_name))
      } else if (item.not_here_office === 1) {
        return ',' + ((this.$i18n.locale === 'bn' ? item.office_name_bn : item.office_name))
      } else {
        return ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    getOfficeData (item, type) {
      if (parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
        let desigObj
        if (type === 'admin') {
          desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.designation_id)
        }
        if (type === 'panel') {
          desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.designation_id)
        }
        if (typeof desigObj !== 'undefined') {
          return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
        } else {
          return ''
        }
      } else {
        return this.$i18n.locale === 'en' ? item.designation_en : item.designation_bn
      }
    },
    dayGetName (value) {
      return moment(value).format('dddd')
    },
    async getCircularWiseData (search) {
      this.load = true
      const routineSearch = {
        circular_memo_no: search.circular_memo_no
      }
      RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleRoutine, routineSearch).then(response => {
        if (response.success) {
          this.routines = response.data
          this.circularData = response.circularData
          this.load = false
        } else {
          this.routines = []
          this.circularData = []
          this.load = false
        }
      })
    },
    async committeeData (search) {
      this.load = true
      const commiteeSearch = {
        fiscal_year_id: search.fiscal_year_id,
        org_id: search.org_id,
        training_title_id: search.training_title_id,
        batch_no: search.batch_no,
        circular_memo_no: search.circular_memo_no
      }
      RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleCommittee, commiteeSearch).then(response => {
        if (response.success) {
          this.committee = response.data
          this.load = false
        } else {
          this.committee = []
          this.load = false
        }
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_config.schedule_setup')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this, this.routines, this.committee, this.circularData)
    },
    async wordExport () {
      this.loader = true
      const sendData = {
        role: this.$store.state.Auth.activeRoleId,
        auth_org: this.$store.state.Auth.authUser.org_id,
        circular_memo_no: this.formData.circular_memo_no,
        fiscal_year_id: this.formData.fiscal_year_id,
        org_id: this.formData.org_id,
        training_title_id: this.formData.training_title_id,
        training_title_en: this.formData.training_title,
        training_title_bn: this.formData.training_title_bn,
        training_type_en: this.formData.training_type,
        training_type_bn: this.formData.training_type_bn,
        training_category_en: this.formData.training_category,
        training_category_bn: this.formData.training_category_bn,
        batch_name_bn: this.formData.batch_name_bn,
        batch_name_en: this.formData.batch_name,
        fiscal_year_bn: this.formData.fiscal_year_bn,
        fiscal_year_en: this.formData.fiscal_year
      }
      const params = Object.assign(
        {
          request_type: 'word',
          local: this.$i18n.locale
        },
        sendData
      )

      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      try {
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, wordExportSchedule, params)

        // Create a Blob and object URL
        var blob = new Blob([result], {
          type: 'application/msword' // Use 'application/msword' for Word documents
        })

        var url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'training_schedule.docx' // Set the desired filename with .docx extension for Word documents
        this.loader = false
        // Trigger the download and cleanup
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        // Handle any errors here
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
  }
}
</script>
